import Log from './Log'
import CookieSessionManager from './CookieSessionManager'
import {TokenSessionManager} from './TokenSessionManager'
import GloboIdClientMap from './GloboIdClientMap'
import RedirectManager from './RedirectManager'


const COOKIE_SESSION_MANAGEMENT = 'cookie'
const TOKEN_SESSION_MANAGEMENT = 'token'

class GloboId {
  constructor() {
    this.isInitialized = false

    this.settings = {}
    this.defaultConfiguration = {
      'url': 'https://id.qa.globoi.com/auth',
      'realm': 'globo.com',
      'grantType': 'code',
      'promiseType': 'native',
      'checkLoginIframe': false,
      'redirectUri': window.location.href.replace(/#.*$/, ''),
      'sessionManagement': TOKEN_SESSION_MANAGEMENT,
      'enable-pkce': true,
      'pkceMethod': 'S256',
    }

    this.requiredSettingsList = [
      'clientId',
      'resource',
    ]
  }

  async setup(settings) {
    if (this.isInitialized) {
      Log.debug('Already set up. Doing nothing')
      return
    }

    if (!(settings && Object.keys(settings).length)) {
      throw new Error('The settings given are invalid')
    }

    //retrocompatibility 1.3.1 - 1.4.0
    const legacyUrlConf = settings.authServerUrl || settings.oidcProvider
    if(legacyUrlConf) {
      const url = new URL(legacyUrlConf)
      settings.url = `${url.protocol}//${url.host}/auth`
      delete settings.authServerUrl
      delete settings.oidcProvider
    }

    this.requiredSettingsList.forEach((key) => {
      if (!settings[key]) {
        throw new Error(`Object settings is missing key "${key}"!`)
      }
    })

    this.settings = {
      ...this.defaultConfiguration,
      ...settings
    }

    let SessionManagerClass

    switch(this.settings.sessionManagement) {

    case COOKIE_SESSION_MANAGEMENT:
      SessionManagerClass = CookieSessionManager
      break

    case TOKEN_SESSION_MANAGEMENT:
      SessionManagerClass = TokenSessionManager
      break

    default:
      throw new Error(`Invalid session manager type: ${this.settings.sessionManagement}`)
    }

    this.isInitialized = true

    Log.debug(`Settings: ${JSON.stringify(this.settings)}`)
    this.sessionManager = new SessionManagerClass(this.settings)
    await this.setupSilentIfEnabled()

    return this.sessionManager.setup()
  }

  _checkIsInitialized() {
    if (this.isInitialized) {
      return
    }

    throw new Error('The lib is not initilized')
  }

  isLogged() {
    this._checkIsInitialized()
    return this.sessionManager.isLogged()
  }

  async setupSilentIfEnabled() {
    if(this.settings.onLoad == 'check-sso') {
      if(this.isLogged()) {
        console.debug('Globoidjs - Avoiding silenth auth. client already logged')
        this.settings.onLoad = ''
      } else {
        console.debug('Globoidjs - Setting up silent auth configs')
        this.settings.silentCheckSsoRedirectUri = this.settings.silentCheckSsoRedirectUri || this.settings.redirectUri
      }
    }
  }

  async login() {
    this._checkIsInitialized()

    await this.sessionManager.login()
    console.debug('Running login')
  }

  async loginCustomSettings(options) {
    Log.debug('Initiating custom login: ', options)
    this._checkIsInitialized()

    await this.sessionManager.loginCustomSettings({...this.settings, ...options})
    console.debug('Running login')
  }

  async logout() {
    this._checkIsInitialized()

    await this.sessionManager.logout()
    Log.debug('Running logout')
  }

  async loadUserInfo(){
    this._checkIsInitialized()
    Log.debug('Running getUserInfo')
    return await this.sessionManager.loadUserInfo()
  }

  async getExtraInfo() {
    console.debug('Getting extra info from clientId:', this.settings.clientId)
    const rm = new RedirectManager()
    return rm.getExtraInfo(this.settings.clientId)
  }

  async getTokens() {
    this._checkIsInitialized()
    return this.sessionManager.getTokens()
  }

}

const initClientMap = async (debug = false) => {
  Log.setDebug(debug)
  Log.debug('Running initQueue')

  window.glb = window.glb || {}
  window.glb.globoIdClientMap = window.glb.globoIdClientMap || new GloboIdClientMap()
}

export {
  GloboId,
  initClientMap,
  COOKIE_SESSION_MANAGEMENT,
  TOKEN_SESSION_MANAGEMENT,
}
